import styled from "@emotion/styled";
import Colors from "/styles/colors";
import { WHITE_COLOR_NAME } from "styles/color-consts";
export const StyledProductTabContentTagsComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;

  .product-sign-up {
    height: auto;

    .signup-text {
      font-weight: 400;
    }
  }

  .product-tags-container {
    display: flex;
    gap: 8px;
  }

  .product-tag {
    font-size: 14px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    color: ${Colors[WHITE_COLOR_NAME]};
    padding: 6px 12px;
    border-radius: 4px;
  }
`;
