import styled from "@emotion/styled";
import Colors from "/styles/colors";
import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
export const StyledProductTabTitle = styled.div`
  padding: 10px 10px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: flex-start;

  .product-logo-wrapper {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    .product-logo {
      opacity: 1;
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      transition: opacity 0.3s ease-in-out;
    }

    .selected-product-logo {
      opacity: 0;
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      transition: opacity 0.3s ease-in-out;
    }
  }

  .product-tab-title-text {
    color: ${Colors.asphalt};
  }

  &.selected-logo {
    .product-tab-title-text {
      color: ${Colors[BRAND_BLACK_TEXT_COLOR]};
    }
  }

  :hover {
    .product-tab-title-text {
      color: ${Colors[BRAND_BLACK_TEXT_COLOR]};
    }
  }

  &.logo-exists {
    &.selected-logo {
      .product-logo-wrapper {
        .product-logo {
          opacity: 0;
        }

        .selected-product-logo {
          opacity: 1;
        }
      }
    }

    :hover {
      .product-logo-wrapper {
        .product-logo {
          opacity: 0;
        }

        .selected-product-logo {
          opacity: 1;
        }
      }
    }
  }

  .product-tab-title-text {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;

    .product-tab-title {
      font-weight: 600;
      line-height: 24px;
    }

    .paragraph-wrapper .paragraph-body {
      font-weight: 400;
    }
  }

  .picture-component {
    img {
      width: 45px;
      object-fit: contain;
    }
  }
`;
