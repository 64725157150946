import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import DesktopHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
const HEADER_LOGO_HORIZONTAL_PADDING = `${DesktopHeaderConstants.HEADER_CONTENT_HORIZONTAL_PADDING}px`;
export const StyledProductsHorizontalTimerTabsComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  width: fit-content;
  padding: 0 ${HEADER_LOGO_HORIZONTAL_PADDING} 0 0;

  .product-tabs-title-container {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }

  .timer-vertical-tabs-desktop-component {
    padding: 0;
    width: 100%;
    max-width: 100%;

    .vertical-timer-tabs-container {
      flex-direction: column;

      .product-tab-content {
        .asset-container {
          width: 100%;
          height: 510px;
        }
      }

      .vertical-timer-tab-arrow-wrapper {
        display: none;
      }

      .vertical-timer-tab-desktop-content-container {
      }

      .desktop-tabs-text-container {
        flex-direction: row;
        min-height: auto;
        margin-right: 0;

        .tab {
          height: fit-content;
          width: 100%;

          &:first-of-type {
            min-width: 250px;
          }

          &:nth-of-type(2) {
            min-width: 230px;
          }

          &:nth-of-type(3) {
            min-width: 235px;
          }

          .vertical-timer-tab-desktop-container {
            margin-bottom: 0;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .vertical-timer-tab-desktop-progress-container {
              display: contents;
              height: 100%;

              .progressbar {
                width: 100%;
                margin-top: 0;
              }
            }

            .vertical-timer-tab-desktop-title-button {
              display: flex;
              justify-content: center;
            }

            .vertical-timer-tab-desktop-title-button-selected {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
`;
