export default {
    maxLimit: 3,
    backgroundColor: "transparent",
    lineHeight: "auto",
    showButton: false,
    alwaysShowProgressbar: true,
    showArrow: false,
    tabs: [
        {
            productType: "work-management",
            title: {
                title: "Work Management",
                body: "Manage projects & tasks",
                productImage: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/local-031023/wm-dark.png",
                lineHeight: "100%"
            },
            tabContent: {
                productTags: [
                    {
                        text: "Projects "
                    },
                    {
                        text: "Tasks"
                    },
                    {
                        text: "Creative"
                    },
                    {
                        text: "Marketing"
                    },
                    {
                        text: "Operations"
                    }
                ],
                asset: {
                    image: {
                        src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/global-041023/global-041023-wm-v1.png"
                    }
                }
            },
            asset: {
                image: {
                    src: null
                }
            }
        },
        {
            productType: "crm",
            title: {
                title: "CRM",
                body: "Streamline sales cycle",
                productImage: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/local-031023/crm-dark.png"
            },
            tabContent: {
                productTags: [
                    {
                        text: "Sales leaders"
                    },
                    {
                        text: "Sales reps"
                    },
                    {
                        text: "Sales ops"
                    },
                    {
                        text: "Account managers"
                    }
                ],
                asset: {
                    image: {
                        src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/global-041023/global-041023-crm-v1.png"
                    }
                }
            },
            asset: {
                image: {
                    src: null
                }
            }
        },
        {
            productType: "dev",
            title: {
                title: "Dev",
                body: "Deliver better products",
                productImage: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/local-031023/dev-dark.png"
            },
            tabContent: {
                productTags: [
                    {
                        text: "R&D"
                    },
                    {
                        text: "Product managers"
                    },
                    {
                        text: "Product designers"
                    },
                    {
                        text: "Agile teams"
                    }
                ],
                asset: {
                    image: {
                        src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/global-041023/global-041023-dev-v1.png"
                    }
                }
            },
            asset: {
                image: {
                    src: null
                }
            }
        }
    ],
    timerEnabled: false,
    tabsTitle: {
        titleText: "Find the right product for your team",
        titleMarginBottom: "20px"
    },
    theme: "background-gray"
};
